<template>
    <v-container>
        <v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.ID == null ? 'Erstellen' : `[${editItem.ID}] '${editItem.title}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form v-model="editValid">
                        <v-container>
                            <v-row>
                                <!--v-col cols="4">
                                    <v-card tile color="box_accent" class="px-2 pt-2">
                                        <v-img height="250px" :src="image_preview" contain />
                                        <v-file-input :rules="image_rules" v-model="editItem.image_upload" accept="image/png, image/jpeg" prepend-icon="" placeholder="Bild" class="pa-0 mb-0 mt-5" show-size>

                                        </v-file-input>
                                    </v-card>
                                </v-col-->
                                <v-col>
                                    <v-text-field v-model="editItem.title" :rules="rule_notempty" label="Titel" required></v-text-field>
                                    <v-text-field v-model="editItem.filename" :rules="rule_notempty" label="Dateiname" required></v-text-field>

                                    <axz-file-picker v-model="editItem.image" :rules="file_rules" label="Bild" accept="image/*" required></axz-file-picker>
                                    <axz-file-picker v-model="editItem.file" :rules="file_rules" label="Datei" required></axz-file-picker>
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.ID == null" @click="create()" :loading="editLoading" :disabled="!editValid">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading" :disabled="!editValid">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Media
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols" :items="$db.media.data" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" show-expand single-expand group-by="public" :loading="loading">
                        <template v-slot:item.actions="{ item }">
                            <v-icon v-if="item.public == '1'" small color="grey" @click="unpublish(item)">fas fa-lock</v-icon>
                            <v-icon v-else small @click="publish(item)">fas fa-globe</v-icon>
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                        <template v-slot:group.header="{ headers, group }">
                            <td :colspan="headers.length" class="text-caption text-uppercase pl-2"><v-icon small class="mr-2">fas fa-{{group == '1' ? 'globe' : 'lock'}}</v-icon>{{group  == '1' ? 'Veröffentlicht' : 'Unveröffentlicht'}}</td>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-row no-gutters>
                                    <v-col cols="2">
                                        <v-img :src="item.image" contain width="240px" class="pr-4 pt-12"/>
                                    </v-col>
                                    <v-col>
                                        <!--v-textarea v-model="item.text" readonly auto-grow></v-textarea-->
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols: [
                //{ text: 'ID', value: 'ID' },
                { text: 'Überschrift', value: 'title' },
                { text: 'Dateiname', value: 'filename' },
                //{ text: 'Größe', value: 'filesize' },
                { text: 'Downloads', value: 'downloads' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            media: [],
            selected: 0,
            editItem: null,
            editValid: false,
            editLoading: false,
            file_rules: [
                value => !!value && value.size < 20000000 || 'File size should be less than 20 MB!',
            ],
            rule_notempty: [
                v => !!v || 'required',
            ],
        }
    },
    computed: {

    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                this.loadMedia(); //or clone edit item !=??!?!?
            }
        },
    },
    methods: {
        loadMedia(){
            this.loading = true;
            this.$db.media.load().then(() => {
                this.loading = false;
            });
        },
        edit(item) {
            if(item != null) {
                this.editItem = Object.assign(item);
            }
            else {
                this.editItem = {
                    ID: null,
                    title: null,
                    filename: null,
                    image: null,
                    file: null,
                }
            }
            this.selected = 1;
        },
        publish(item) {
            this.$db.media.publish(item.ID).then(() => {
                //this.loadMedia();
                this.$db.media.load();
            });
        },
        unpublish(item) {
            this.$db.media.unpublish(item.ID).then(() => {
                //this.loadMedia();
                this.$db.media.load();
            }); 
        },
        async create(){
            this.editLoading = true;
            this.$db.media.create(
                this.editItem.title, 
                this.editItem.filename, 
                await this.$db.files.create(this.editItem.image), 
                await this.$db.files.create(this.editItem.file)
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadMedia();
            });
        },
        async update(){
            this.editLoading = true;
            this.$db.media.update(
                this.editItem.ID,
                this.editItem.title, 
                this.editItem.filename, 
                typeof this.editItem.image == 'object' ? await this.$db.files.createOrUpdate(this.editItem.image.identifier, this.editItem.image) : this.editItem.image,
                typeof this.editItem.file == 'object' ? await this.$db.files.createOrUpdate(this.editItem.file.identifier, this.editItem.file) : this.editItem.file,
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.loadMedia();
            });
        }
    },
    mounted() {
        this.loadMedia();
    }
}
</script>